module services {
    export module master {
        export class productCategoryService implements interfaces.master.IProductCategoryService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getProductCategoryByParent(entityId: number, userId: number, parentCategoryId:number): ng.resource.IResourceClass<interfaces.master.IProductCategory> {

                return this.$resource<interfaces.master.IProductCategory>(this.ENV.DSP_URL + "ProductCategory/GetProductCategoryByParent", {
                    entityId: entityId,
                    userId: userId,
                    parentCategoryId: parentCategoryId
                    
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }
        }
    }
    angular.module("app").service("productCategoryService", services.master.productCategoryService);
}